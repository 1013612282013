import React, { FC, useEffect } from "react";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "ckeditor5-build-classic-plus";
import { getHeaders } from "../../../interceptor/axiosInstance";
import Error from "../Error";
import "./editor.scss";

interface EditorProps {
  onChangeHandler: (content: string) => void;
  value: string;
  error?: string;
  readOnly?: boolean;
}

const headers = getHeaders();

const Editor: FC<EditorProps> = ({
  value,
  onChangeHandler,
  error,
  readOnly,
}) => {
  const cleanOfficeHtml = (html: string) => {
    const cleaned = html
      // Remove Office/Word namespace tags and metadata
      .replace(/<\?xml[^>]*>/gi, "")
      .replace(/<!--\[if.*?endif\]-->/gi, "")
      // .replace(/<style[^>]*>[\s\S]*?<\/style>/gi, "")
      .replace(/<o:[^>]*>[\s\S]*?<\/o:[^>]*>/gi, "")
      .replace(/<(\/)?(w|o|v|m):[^>]*>/gi, "")
      .replace(/<meta[^>]*>/gi, "")
      .replace(/<link[^>]*>/gi, "")
      .trim();
    return cleaned;
  };

  const removeBlankLines = (html: string) => {
    return html
      .replace(/\u00A0/g, " ") // Convert non-breaking spaces to regular spaces
      .replace(/<p[^>]*>(\s|&nbsp;)*<\/p>/gi, "") // Remove empty or whitespace-only <p> tags
      .replace(/\n{2,}/g, "\n") // Normalize excessive newlines if they occur
      .trim();
  };

  return (
    <div className="editor">
      <CKEditor
        editor={ClassicEditor}
        data={value}
        config={{
          simpleUpload: {
            uploadUrl: "https://google.com/test",
            headers: headers,
          },
          isReadOnly: !!readOnly,
          clipboard: {
            // Preserve formatting when pasting from MS Office
            contentTransformation: [],
          },
          pasteFromOffice: {
            // Allows pasting Word content with formatting
            preserveHtmlStructure: true,
            styles: true,
          },
          // plugins: [...ClassicEditor.builtinPlugins],
        }}
        onReady={(editor: any) => {
          editor.editing.view.document.on("paste", (evt: any, data: any) => {
            const html = data.dataTransfer.getData("text/html");
            if (
              html &&
              html.includes("urn:schemas-microsoft-com:office:word")
            ) {
              evt.stop(); // Stop the default paste behavior
              const cleanedHtml = cleanOfficeHtml(html);
              // Convert cleaned HTML into CKEditor's view format
              const viewFragment = editor.data.processor.toView(cleanedHtml);
              const modelFragment = editor.data.toModel(viewFragment);
              // Insert into editor
              editor.model.insertContent(modelFragment);
            }
          });
        }}
        onChange={(event: any, editor: any) => {
          try {
            const raw = editor.getData();
            const parsed = removeBlankLines(raw);
            onChangeHandler(parsed);
          } catch (e) {
            console.error("CKEditor paste crash:", e);
            onChangeHandler("");
          }
        }}
        onFileUploadRequest
      />
      <Error message={error ?? ""} />
    </div>
  );
};

export default Editor;
